import React, { useState } from "react"
import { Layout, Row, Col } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import BurgerMenuContext from "../../../context/burger-menu-context"
import NavCtasContext from "../../../context/nav-ctas-context"
import DesktopNav from "./navigation/desktop-nav"
import MobileNav from "./navigation/mobile-nav"
import { colors, viewports } from "../../../theme/vars"
import { ctas } from "../../../config/nav-config"
import useWindowSize from "../../../hooks/use-window-size"
import ContactUsButton from "./contact-us-button"
import { Link } from "gatsby"

const { lgOrBigger } = viewports

const Header = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false)
  const toggleMobileMenu = () => setMobileMenuActive(!mobileMenuActive)
  const { width } = useWindowSize()

  const burgerContext = {
    mobileMenuActive,
    toggleMobileMenu,
  }

  const ctasContext = ctas

  return (
    <Layout.Header style={{ minHeight: "68px !important" }}>
      <NavCtasContext.Provider value={ctasContext}>
        <BurgerMenuContext.Provider value={burgerContext}>
          <Row align="middle">
            <MobileNav />
            <Col xs={5} lg={0} />
            <Col xs={7} lg={5} xl={3} align="middle" className="header__logo">
              <Link to="/">
                <StaticImage placeholder="none" src="../../../images/bp_logo.png" alt="logo" />
              </Link>
            </Col>
            <Col xs={6} lg={0} />
            {/* <Col xs={2} lg={0} style={{ marginTop: "-9px" }}>
              <ContactUsButton />
            </Col> */}
            <DesktopNav />
          </Row>
        </BurgerMenuContext.Provider>
      </NavCtasContext.Provider>

      <style jsx global>{`
        header.ant-layout-header {
          background: ${colors.secondary};
          position: fixed;
          width: 100%;
          max-width: 1440px;
        }

        @media ${viewports.mdOrSmaller} {
          header.ant-layout-header {
            padding: 0 20px;
            background: #fff;
            position: fixed;
            width: 100%;
            z-index: 10;
          }
        }

        .header__logo {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .header__logo img {
          padding: 5px 0;
          max-height: 70px;
          width: auto;
        }

      `}</style>
    </Layout.Header>
  )
}

export default Header
