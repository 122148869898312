const screenSm = `576px`
const screenMd = `768px`
const screenLg = `992px`
const screenXl = `1200px`

const screenSmMin = screenSm
const screenMdMin = screenMd
const screenLgMin = screenLg
const screenXlMin = screenXl

const screenXsMax = `575px`
const screenSmMax = `767px`
const screenMdMax = `991px`
const screenLgMax = `1199px`
const screenXlMax = `1599px`

export const viewports = {
  xsOnly: `only screen and (max-width: ${screenXsMax})`,
  allExceptXs: `only screen and (min-width: ${screenXsMax})`,
  smOnly: `only screen and (max-width: ${screenSmMax}) and (min-width: ${screenSm})`,
  smOrSmaller: `only screen and (max-width: ${screenSmMax})`,
  smOrBigger: `only screen and (min-width: ${screenSmMin})`,
  mdOnly: `only screen and (max-width: ${screenMdMax}) and (min-width: ${screenMd})`,
  mdOrSmaller: `only screen and (max-width: ${screenMdMax})`,
  mdOrBigger: `only screen and (min-width: ${screenMdMin})`,
  lgOnly: `only screen and (max-width: ${screenLgMax}) and (min-width: ${screenLg})`,
  lgOrSmaller: `only screen and (max-width: ${screenLgMax})`,
  lgOrBigger: `only screen and (min-width: ${screenLgMin})`,
  xlOnly: `only screen and (max-width: ${screenXlMax}) and (min-width: ${screenXl})`,
  xlOrSmaller: `only screen and (max-width: ${screenXlMax})`,
  xlOrBigger: `only screen and (min-width: ${screenXlMin})`,
}

export const colors = {
  bodyBackground: "#fff",
  layoutBackground: "#fff",
  headerBackground: "#1D2033",
  simpleHeaderBackground: "#fff",
  footerBackground: "#fff",
  primary: "#4CA2F6",
  secondary: "#fff",
  // info: '#1769FF',
  // success: '#00AB4F',
  // processing: '#1769FF',
  // error: '#F73E3C',
  // highlight: '#F73E3C',
  // warning: '#FFC043',
  // normal: '#d9d9d9',
  white: "#fff",
  black: "#000",
  gray: "#ededed",
  // textGray: '#C4C4C4',
  // textDarkGray: '#2C2C2C',
  // paragraph: '#748093',
  // black200: '#445469',
  text: "#656F89",
  textSecondary: "#3B3A40",
  textInverse: "#000",
  textDark: "#3B3A40",
  textSecondaryDark: "#1D2033",
  textSelectionBg: "#66B083",
}

// export const fontFamily = `'sofia-pro', sans-serif`;
