import React from "react"
import { colors, viewports } from "../../../theme/vars"

const FooterStyles = () => (
  <style jsx global>{`
    .footer {
      background: ${colors.secondary};
      border-top: 1px solid ${colors.white};
      box-shadow: 0px 4px 10px rgb(0 0 0 / 23%);
    }

    .footer__logo {
      max-width: 50px;
      margin-left: 10px;
    }

    .footer__left-side-info {
      font-size: 12px;
      color: ${colors.black};
      padding: 3px 0;
    }

    h2.ant-typography.footer__menu-header {
      color: ${colors.black};
      padding: 0 20px;
      margin-bottom: 60px;
      text-align: center;
    }

    .footer__menu-list {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .footer__menu-item {
      padding: 5px;
      width: 100%;
      font-size: 18px;
    }
    .footer__menu-item a {
     color: ${colors.black};
    }
    .footer__menu-item a:hover {
      color: #2796D6;
     }

    .footer__copyright {
      text-align: center;
      font-size: 12px;
      color: #000;
      margin-top: 20px;
      font-family: "Inter";
      font-size: 16px;
    }

    @media ${viewports.mdOrSmaller} {
      .footer h2.ant-typography {
        text-align: center;
      }
    }
    @media ${viewports.smOrSmaller} {
      .footer__logo {
        margin-top: 20px;
      }
    }
  `}</style>
)

export default FooterStyles
