import React, { useContext } from "react"
import { Col } from "antd"
import NavMenu from "./nav-menu"
// import NavCtasContext from "../../../../context/nav-ctas-context"
// import NavCtas from "./nav-ctas"
import { colors } from "../../../../theme/vars"

const DesktopNav = () => {
  // const { withCtas } = useContext(NavCtasContext)

  return (
    <>
      <Col xs={0} lg={19} xl={20} className="header__nav">
        <NavMenu mode="horizontal" />
      </Col>
      {/* <Col xs={0} lg={withCtas ? 4 : 0} xl={withCtas ? 4 : 0}>
        {withCtas ? <NavCtas /> : null}
      </Col> */}

      <style jsx global>{`
        .header__nav {
          //display: flex;
          //justify-content: flex-end;
        }
        .header__nav ul {
          border-bottom: none;
          width: 100%;
          background: ${colors.white};
          display: flex;
          justify-content: flex-end;
        }
      `}</style>
    </>
  )
}

export default DesktopNav
