import React from "react"
import { oneOf } from "prop-types"
import { Menu } from "antd"
import { Link } from "gatsby"
import { navItems } from "../../../../config/nav-config"

const NavMenu = ({ mode }) => (
  <>
    <Menu mode={mode}>
      {navItems.map(({ key, name, href }) => {
        return (
          <Menu.Item key={key}>
            <Link to={href} activeClassName="active">
              {name}
            </Link>
          </Menu.Item>
        )
      }
      )}
    </Menu>
  </>
)

NavMenu.propTypes = {
  mode: oneOf(["inline", "horizontal"]).isRequired,
}

export default NavMenu
