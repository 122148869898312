import React, { useContext } from "react"
import { Row, Col, Button } from "antd"
import NavCtasContext from "../../../../context/nav-ctas-context"
import { viewports, colors } from "../../../../theme/vars"
import ModalContext from "../../../../context/modal-context"

const { mdOrSmaller, lgOnly } = viewports

const NavCtas = () => {
  const { withCtas, primary, secondary } = useContext(NavCtasContext)
  const { setIsModalVisible } = useContext(ModalContext)

  if (!withCtas) {
    return null
  }

  return (
    <>
      <Row justify="end" className="nav-ctas">
        {/* {secondary &&
          <Col xs={24} lg={11}>
            <Button
              className="nav-ctas__secondary"
              shape="round"
              size="large"
              href={secondary.href}
            >
              {secondary.label}
            </Button>
          </Col>
        } */}
        {primary &&
          <Col xs={24} lg={11}>
            <Button
              className="nav-ctas__primary"
              type="primary"
              shape="round"
              size="large"
              // href={primary.href}
              onClick={() => setIsModalVisible(true)}
            >
              {primary?.label}
            </Button>
          </Col>
        }

      </Row>

      <style jsx global>{`
        .nav-ctas {
          width: 100%;
          font-family: "Inter";
          
        }
        .nav-ctas__primary {
          font-size: 18px !important;
        }

        .nav-ctas .ant-btn {
          min-width: 100%;
          font-size: 14px;
        }

        .nav-ctas__secondary {
          color: ${colors.textSecondaryDark};
        }

        @media ${lgOnly} {
          .nav-ctas .ant-btn {
            padding: 0 10px;
          }
        }

        @media ${mdOrSmaller} {
          .nav-ctas {
            margin-top: 20px;
          }

          .nav-ctas .ant-btn {
            margin: 10px 0;
          }
        }
      `}</style>
    </>
  )
}

export default NavCtas
