import React from "react"
import { colors, viewports } from "../../theme/vars"

const GlobalStyles = () => (
  <style jsx global>{`
    html {
      scroll-behavior: smooth;
    }

    body {
      background: ${colors.bodyBackground};
    }

    main.ant-layout-content {
      margin-top: 62px;
    }

    .ant-layout {
      min-height: 100vh;
      max-width: 1440px;
      margin: 0 auto;
      background: ${colors.layoutBackground};
      position: relative;
    }

    h1.ant-typography {
      font-family: "Inter";
      color: ${colors.primary};
      font-size: 36px !important;
    }

    h2.ant-typography {
      font-family: "Inter";
      color: ${colors.primary};
      font-size: 20px !important;
    }

    h3.ant-typography {
      color: ${colors.textSecondary};
      font-weight: 500;
    }

    h4.ant-typography {
      color: ${colors.primary};
      font-weight: 500;
    }

    h5.ant-typography {
      color: ${colors.textSecondaryDark};
      font-weight: 600;
      text-transform: uppercase;
    }


    .ant-carousel .slick-prev,
    .ant-carousel .slick-next,
    .ant-carousel .slick-prev:hover,
    .ant-carousel .slick-next:hover,
    .ant-carousel .slick-prev:focus,
    .ant-carousel .slick-next:focus {
      font-size: 12px;
      color: ${colors.primary};
    }
    .ant-carousel .slick-prev {
      left: -10px;
    }
    .ant-carousel .slick-next {
      right: -20px;
    }
    .dotsClass{
      color: #000;
    }

    header.ant-layout-header {
      z-index: 11 !important;
      // box-shadow: 0px 1px 9px rgb(0 0 0 / 13%);
    }

    header.ant-layout-header.simple-header {
      border-radius: 0px 0px 40px 40px;
      border-bottom: none;
    }

    .ant-progress-inner {
      background-color: #4a4d5c;
    }
    .ant-progress-bg {
      background-color: ${colors.primary};
    }

    @media ${viewports.lgOrBigger} {
      //.assistant-start__cover-wrapper {
      //  display: block;
      //}
      h1.ant-typography {
        font-size: 48px !important;
      }
      h2.ant-typography {
        font-size: 32px !important;
      }
    }
  `}</style>
)

export default GlobalStyles
