import React, { useContext, useEffect, useState } from "react"
import { Drawer, Col } from "antd"
import Burger from "react-css-burger"
import BurgerMenuContext from "../../../../context/burger-menu-context"
import { viewports, colors } from "../../../../theme/vars"
import NavMenu from "./nav-menu"
import NavCtas from "./nav-ctas"
import useWindowSize from "../../../../hooks/use-window-size"

const { xsOnly, smOnly, lgOrBigger } = viewports

const MobileNav = () => {
  const [burger, setBurger] = useState(null)
  const { mobileMenuActive, toggleMobileMenu } = useContext(BurgerMenuContext)
  const { width } = useWindowSize()

  useEffect(() => {
    const burgerIcon = (
      <Burger
        active={mobileMenuActive}
        onClick={toggleMobileMenu}
        burger="spin"
        style={{ margin: 0 }}
        color={width <= 991 ? "#000" : colors.white}
      />
    )

    setBurger(burgerIcon)
  }, [mobileMenuActive, toggleMobileMenu, width])

  return (
    <>
      <Col xs={4} lg={0} className="header__burger">
        {burger}
      </Col>

      <Drawer
        className="mobile-menu"
        placement="right"
        closable={false}
        visible={mobileMenuActive}
      >
        <NavMenu mode="inline" />
        {/* <NavCtas /> */}
      </Drawer>

      <style jsx global>{`
        div.ant-drawer-content-wrapper {
          box-shadow: none !important;
        }

        .mobile-menu {
          margin-top: 62px;
        }

        .header__burger button span {
          color: ${colors.white};
        }

        @media ${xsOnly} {
          .ant-drawer-content-wrapper {
            width: 100% !important;
          }
        }

        @media ${smOnly} {
          .ant-drawer-content-wrapper {
            width: 300px !important;
          }
        }

        @media ${lgOrBigger} {
          .mobile-menu {
            display: none;
          }
        }
      `}</style>
    </>
  )
}

export default MobileNav
