import React from "react"
import { array, bool } from "prop-types"
import { Row, Col, Layout as AntLayout, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import FooterStyles from "./styles"

const { Title } = Typography

const Footer = ({ leftSideInfo, menuItems, menuItemsFooter, hidden }) => {
  if (hidden) {
    return null
  }

  return (
    <AntLayout.Footer className="footer">
      <Row justify="center">
    

          <div className="footer__copyright">
            © Copyright {new Date().getFullYear()}. All rights reserved. Bryan Post
          </div>
          <StaticImage
            className="footer__logo"
            src="../../../images/bp_logo.png"
            alt="footer logo"
            placeholder="none"
          />

      </Row>

      <FooterStyles />
    </AntLayout.Footer>
  )
}

Footer.propTypes = {
  leftSideInfo: array,
  menuItems: array.isRequired,
  menuItemsFooter: array.isRequired,
  hidden: bool,
}

Footer.defaultProps = {
  leftSideInfo: [],
  hidden: false,
}

export default Footer
