import React, { useState, useEffect } from "react"
import { bool } from "prop-types"
import { Layout as AntLayout } from "antd"
import _JSXStyle from "styled-jsx/style"
// import "@fontsource/inter"
// import "@fontsource/poppins"
// import "@fontsource/poppins/300.css"
// import "@fontsource/poppins/500.css"

import Header from "./header"
import SimpleHeader from "./header/simple-header"
import Footer from "./footer"
import ModalContext from "../../context/modal-context"
import GlobalStyles from "./global-styles"

if (typeof global !== "undefined") {
  Object.assign(global, { _JSXStyle })
}

const Layout = ({
  children,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [docWindow, setDocWindow] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setDocWindow(window)
    }
  }, [])

  // useEffect(() => {
  //   history.pushState(
  //     "",
  //     document.title,
  //     window.location.pathname + window.location.search
  //   )
  // }, [docWindow?.location?.hash])

  return (
    <AntLayout>
      <ModalContext.Provider value={{ isModalVisible, setIsModalVisible }}>
          <Header />
        <AntLayout.Content>{children}</AntLayout.Content>
        <Footer/>

        <GlobalStyles />
      </ModalContext.Provider>
    </AntLayout>
  )
}

Layout.propTypes = {
  withSimpleHeader: bool,
  hideCta: bool,
  hideHeader: bool,
  hideFooter: bool,
}

Layout.defaultProps = {
  withSimpleHeader: false,
  hideCta: false,
  hideHeader: false,
  hideFooter: false,
}

export default Layout
