export const simpleNav = {
  cta: {
    label: "Simple",
    href: "tel:0123456789",
  },
}

export const ctas = {
  withCtas: true,
  primary: {
    label: "(773) 818-0553",
    href: "tel:+17738180553",

  },
  secondary: {
    label: "Get a Quote",
    href: "/",
  },
}

export const navItems = [
  {
    key: "terms",
    name: "Terms & Conditions",
    href: "/terms-and-conditions",
  },
  {
    key: "faq",
    name: "FAQ",
    href: "/faq",
  },
]
