import React from "react"
import { bool } from "prop-types"
import { Link } from "gatsby"
import { Layout, Row, Col, Button } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import { colors, viewports } from "../../../../theme/vars"
import { simpleNav } from "../../../../config/nav-config"

const { cta } = simpleNav

const SimpleHeader = ({ hideCta, hideHeader }) => {
  if (hideHeader) {
    return null
  }

  return (
    <Layout.Header className="simple-header">
      <Row justify="space-between">
        <Col xs={12} sm={8}>
          <Link to="/">
            <StaticImage
              src="../../../../images/logo.png"
              alt="logo"
              placeholder="none"
              style={{ maxWidth: 202 }}
            />
          </Link>
        </Col>
        <Col xs={12} sm={{ span: 8, offset: 8 }} className="simple-header__cta">
          <Button
            hidden={hideCta}
            type="primary"
            shape="round"
            size="large"
            href={cta.href}
          >
            {cta.label}
          </Button>
        </Col>
      </Row>

      <style jsx global>{`
        .simple-header {
          background: ${colors.simpleHeaderBackground};
          box-shadow: ${hideCta
            ? 0
            : `0px 100px 80px rgba(0, 0, 0, 0.14),
            0px 41.7776px 33.4221px rgba(0, 0, 0, 0.10064),
            0px 22.3363px 17.869px rgba(0, 0, 0, 0.083455),
            0px 12.5216px 10.0172px rgba(0, 0, 0, 0.07),
            0px 6.6501px 5.32008px rgba(0, 0, 0, 0.056545),
            0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0393604)`};
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          z-index: 11 !important;
        }

        .simple-header__cta {
          text-align: right !important;
        }
        .simple-header__cta .ant-btn {
          min-width: 100%;
          font-size: 14px;
        }

        @media ${viewports.mdOrBigger} {
          .simple-header__cta .ant-btn {
            min-width: 250px;
          }
        }
      `}</style>
    </Layout.Header>
  )
}

SimpleHeader.propTypes = {
  hideCta: bool,
  hideHeader: bool,
}

SimpleHeader.defaultProps = {
  hideCta: false,
  hideHeader: false,
}

export default SimpleHeader
